<template>
	<div :class="themeClass" class="couponCont width-fill margin-t-10 flex-column">
		<div class="coupon-Box">
			<div>
				<div id="cuopon-btn" @click="collectCouponsClick" class="pointer">领券中心</div>
			</div>
			<div class="coupon-title">优惠券</div>
			<el-tabs v-model="activeName" @tab-click="handleClick" class="coupon-tab">
				<el-tab-pane label="未使用" name="first" v-if="usedList != null">
					<div class="accountCont width-fill margin-t-15">
						<div class="coupList" v-for="(item,index) of unusedList" :key="index">
							<div class="collectCoupons-card flex-row coupon-con">
								<div class="collectCoupons-card-left">
									<div class="collectCoupons-card1 flex-row-center-center">
										<p class="collectCoupons-card2">¥</p>
										<p :style="item.fDeductionMoney.toString().length <= 4 ? 'font-size:24px' : (item.fDeductionMoney.toString().length >= 5 && item.fDeductionMoney.toString().length <= 6 ? 'font-size: 18px' : 'font-size: 15px')" class="collectCoupons-card3">{{item.fDeductionMoney}}</p>
									</div>
									<div class="collectCoupons-card4 text-center">
										购物优惠
									</div>
								</div>
								<div class="collectCoupons-card-right ">
									<div class="collectCoupons-card5">
										<div class="collectCoupons-card6 margin-b-5 flex-row right-top-name">{{item.fCouponName}}</div>
										<p class="collectCoupons-card7 margin-b-10">{{getDate(item.fBeginTime)}}至{{getDate(item.fEndTime)}}</p>
										<div class="collectCoupons-card6">
											<p class="collectCoupons-card29">满{{item.fMinSaleMoney}}减{{item.fDeductionMoney}}</p><p class="collectCoupons-card8 border-FF0000 font-size11 radius10 text-center font-color-FF0000 margin-b-10" @click="useClick(item)">立即使用</p>
										</div>
										
										<div>
											<p></p>
										</div>
									</div>
									<div class="collectCoupons-card9" @click="slideBtn(item)" style="position: relative; left: 10px;height: 11px;">
										<!-- <p>优惠券有效期</p> -->
										<!-- <i class="el-icon-arrow-up font-size16  font-color-999" v-if="item.tipsShow"></i> -->
										<!-- <i class="iconfont icon-arrow-down font-size20 font-color-999" v-if="!item.tipsShow"></i> -->
										<!-- <i class="el-icon-arrow-up font-size13 font-color-999"></i> -->
										<el-dropdown style="width: 100%;position: relative;" size="small">
										  <span class="el-dropdown-link" style="display: inline-block;width: 100%;position: absolute;left: -10px;">
										    <p style="display: inline-block;">使用规则</p><i class="el-icon-arrow-down el-icon--right" style="display: inline-block; float: right;"></i>
										  </span>
										  
										  <el-dropdown-menu slot="dropdown" style="width: 283.69px;">
											<el-dropdown-item v-for="(items,indexs) of item.useRules" :key="indexs">{{indexs+1}}.{{items.fUseDescribe}}</el-dropdown-item>
										  </el-dropdown-menu>
										</el-dropdown>
									</div>
									<!-- <span class="iconfont stateUse font-color-C9C9C9">&#xe67e;</span> -->
								</div>
							</div>
							<div v-show="item.tipsShow" class="coupon-con  margin-t-2 padding-0 font-size12 font-color-666">
								<div v-for="(itemChild,index) of item.useRules" :key="'itemChild' + index" class="flex-row">
									<span class="margin-r-6  font-size14 font-weight700 font-color-999">·</span>
									<span style="line-height: 18px;">{{itemChild}}</span>
								</div>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="已使用" name="second" v-if="usedList != null">
					<div class="accountCont width-fill margin-t-15">
						<div class="coupList" v-for="(item,index) of usedList" :key="index">
							<div class="collectCoupons-card flex-row coupon-con">
								<div class="collectCoupons-card-left">
									<div class="collectCoupons-card1-none flex-row-center-center">
										<p class="collectCoupons-card2">¥</p>
										<p :style="item.fDeductionMoney.toString().length <= 4 ? 'font-size:24px' : (item.fDeductionMoney.toString().length >= 5 && item.fDeductionMoney.toString().length <= 6 ? 'font-size: 18px' : 'font-size: 15px')" class="collectCoupons-card3">{{item.fDeductionMoney}}</p>
									</div>
									<div class="collectCoupons-card4-none1 text-center">
										购物优惠
									</div>
								</div>
								<div class="collectCoupons-card-right ">
									<div class="collectCoupons-card5">
										<div class="collectCoupons-card6 margin-b-5 flex-row right-top-name" style="color: #999999;">{{item.fCouponName}}</div>
										<p class="collectCoupons-card7 margin-b-10">有效期:{{getDate(item.fBeginTime)}}至{{getDate(item.fEndTime)}}</p>
										<div class="collectCoupons-card6">
											<p class="collectCoupons-card29" style="color: #999999;">满{{item.fMinSaleMoney}}减{{item.fDeductionMoney}}</p>
										</div>
									</div>
									<!-- <div class="collectCoupons-card6">
										<p class="collectCoupons-card29">满{{item.fMinSaleMoney}}减{{item.fDeductionMoney}}</p>
									</div> -->
									
									<div class="collectCoupons-card9" @click="slideBtn(item)" style="position: relative; left: 10px;height: 11px;">
									<!-- 	<p>优惠券有效期</p> -->
							<!-- 			<i class="el-icon-arrow-up font-size16  font-color-999" v-if="item.tipsShow"></i>
										<i class="iconfont icon-arrow-down font-size20 font-color-999" v-if="!item.tipsShow"></i> -->
										<!-- <p>优惠券有效期</p> -->
										<!-- <i class="el-icon-arrow-up font-size16  font-color-999" v-if="item.tipsShow"></i>
										<i class="iconfont icon-arrow-down font-size20 font-color-999" v-if="!item.tipsShow"></i> -->
										<el-dropdown style="width: 100%;position: relative;" size="small">
										  <span class="el-dropdown-link" style="display: inline-block;width: 100%;position: absolute;left: -10px;">
										    <p style="display: inline-block;">使用规则</p><i class="el-icon-arrow-down el-icon--right" style="display: inline-block; float: right;"></i>
										  </span>
										  
										  <el-dropdown-menu slot="dropdown" style="width: 283.69px;">
											<el-dropdown-item  v-for="(items,indexs) of item.useRules" :key="indexs" >{{indexs+1}}.{{items.fUseDescribe}}</el-dropdown-item>
										  </el-dropdown-menu>
										</el-dropdown>
									</div>
									<span class="iconfont stateUse font-color-C9C9C9">&#xe67f;</span>
								</div>
							</div>
							<div v-show="item.tipsShow" class="coupon-con  margin-t-2 padding-8 font-size12 font-color-666">
								<div v-for="(itemChild,index) of item.useRules" :key="'itemChild' + index" class="flex-row">
									<span class="margin-r-6  font-size14 font-weight700 font-color-999">·</span>
									<span style="line-height: 18px;">{{itemChild}}</span>
								</div>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="已失效" name="third" v-if="usedList != null">
					<div class="accountCont width-fill margin-t-15">
						<div class="coupList" v-for="(item,index) of expireList" :key="index">
							<div class="collectCoupons-card flex-row coupon-con">
								<div class="collectCoupons-card-left">
									<div class="collectCoupons-card1-none flex-row-center-center">
										<p class="collectCoupons-card2">¥</p>
										<p :style="item.fDeductionMoney.toString().length <= 4 ? 'font-size:24px' : (item.fDeductionMoney.toString().length >= 5 && item.fDeductionMoney.toString().length <= 6 ? 'font-size: 18px' : 'font-size: 15px')" class="collectCoupons-card3">{{item.fDeductionMoney}}</p>
									</div>
									<div class="collectCoupons-card4-none1 text-center">
										购物优惠
									</div>
								</div>
								<div class="collectCoupons-card-right ">
									<div class="collectCoupons-card5">
										<div class="collectCoupons-card6 margin-b-5 flex-row right-top-name" style="color: #999999;">{{item.fCouponName}}</div>
										<p class="collectCoupons-card7 margin-b-10">有效期：{{getDate(item.fBeginTime)}}至{{getDate(item.fEndTime)}}</p>
										<!-- 	<p v-if="item.useType == 1" class="collectCoupons-card8 border-FF0000 font-size11 radius10 text-center font-color-FF0000 margin-b-10">立即使用</p>
										<p  v-if="item.useType == 0" class="collectCoupons-card8 border-FF0000 font-size11 radius10 text-center font-color-fff bg-FF0000 margin-b-10">领取</p> -->
										<div class="collectCoupons-card6">
											<p class="collectCoupons-card29" style="color: #999999;">满{{item.fMinSaleMoney}}减{{item.fDeductionMoney}}</p>
										</div>
									</div>
									<div class="collectCoupons-card9" @click="slideBtn(item)" style="position: relative; left: 10px;height: 11px;">
										<!-- <p>优惠券有效期</p> -->
										<!-- <i class="el-icon-arrow-up font-size16  font-color-999" v-if="item.tipsShow"></i>
										<i class="iconfont icon-arrow-down font-size20 font-color-999" v-if="!item.tipsShow"></i> -->
										<el-dropdown style="width: 100%;position: relative;" size="small">
										  <span class="el-dropdown-link" style="display: inline-block;width: 100%;position: absolute;left: -10px;">
										    <p style="display: inline-block;">使用规则</p><i class="el-icon-arrow-down el-icon--right" style="display: inline-block; float: right;"></i>
										  </span>
										  
										  <el-dropdown-menu slot="dropdown" style="width: 283.69px;">
											<el-dropdown-item v-for="(items,indexs) of item.useRules" :key="indexs" >{{indexs+1}}.{{items.fUseDescribe}}</el-dropdown-item>
										  </el-dropdown-menu>
										</el-dropdown>
									</div>
									<span class="iconfont stateUse font-color-C9C9C9">&#xe67e;</span>
								</div>
							</div>
							<div v-show="item.tipsShow" class="coupon-con  margin-t-2 padding-8 font-size12 font-color-666">
								
								<div v-for="(itemChild,index) of item.useRules" :key="'itemChild' + index" class="flex-row">
									<span class="margin-r-6  font-size14 font-weight700 font-color-999">·</span>
									<span style="line-height: 18px;">{{itemChild}}</span>
								</div>
							</div>
						</div>
					</div>
				</el-tab-pane>

			</el-tabs>
		</div>
    <pagination :total="total" :currentPage="page" :pagesize="maxResultCount"
    @handleCurrentChange="select"		 />
	</div>
</template>
<script>
	import pagination from '@/components/pagination/pagination';
	import {
		mapGetters
	} from "vuex";
	export default {
    components: {
			pagination,

		},
		data() {
			return {
        total: 0, // 条数
				page: 1, // 页数
				maxResultCount: 9,
				activeName: 'first',
				collectCouponsPopupData: [{
						popupPrice: '20',
						popupTitle: '昌吉天山-每满2000元减20元',
						popupTermOfValidity: '有效期:2020-10-31至2020-10-31',
						useType: 1,
						tipsShow: false,
						useRules: [{
								tip: '本优惠券仅用于购买昌吉天山水泥时使用',
							},
							{
								tip: '有效期内只可使用一次',
							},
							{
								tip: '一笔订单只能使用一次优惠，且不与其他优惠同享'
							}
						]
					},
					{
						popupPrice: '20',
						popupTitle: '昌吉天山-每满2000元减20元',
						popupTermOfValidity: '有效期至2020-10-31',
						useType: 0,
						tipsShow: false,
						useRules: [{
								tip: '本优惠券仅用于购买昌吉天山水泥时使用',
							},
							{
								tip: '有效期内只可使用一次',
							},
							{
								tip: '一笔订单只能使用一次优惠，且不与其他优惠同享'
							}
						]
					},

				],
				unusedList:[],
				usedList:[],
				expireList:[],
			};
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		created(){
			// this.getUsedList()
			this.getUnusedList()
			// this.getExpireList()
		},
		methods: {
      select(page) {
        this.page = page;
        // this.maxResultCount = 20
				if(this.activeName=='first'){
					this.getUnusedList()
				}
				if(this.activeName=='second'){
					this.getUsedList()
				}
				if(this.activeName=="third"){
					this.getExpireList()
				}
      },
			// 立即使用点击事件
			useClick(id){
				//跳转商品列表
				console.log(id);
        if(id.fShopUnitID == '-1') {
        this.$router.push({
					name: 'LimitedActivities'
				});
        }
        else {
        this.$router.push({
          name: 'LimitedShopActivities',
          query: { id: id.fShopUnitID }
        });
        }
				// this.$router.push({
				// 	path: '/Goods',
				// 	query: {},
				// });
			},
			// 未使用优惠券
			getUnusedList() {
				this.ApiRequestPost('api/mall/ebactivity/customerreceiverecord/get-unused-list', {
					// "fCustomerID": 0,
					// "maxResultCount": 10,
					"filter": "",
					"sorting": "",
          maxResultCount: this.maxResultCount,
					skipCount: (this.page - 1) * this.maxResultCount
				}).then(res => {
					console.log('优惠券')
					this.unusedList=res.obj.items
					this.unusedList.forEach(item=>{
						item.tipsShow=false
					})
          this.total = res.obj.totalCount;
					console.log('unusedList',this.unusedList);
				}).catch(err => {
					
				})
			},
			// 已使用优惠券
			getUsedList() {
				this.ApiRequestPost('api/mall/ebactivity/customerreceiverecord/get-used-list', {
					// "fCustomerID": 0,
					// "maxResultCount": 10,
					"filter": "",
					"sorting": "",
          maxResultCount: this.maxResultCount,
					skipCount: (this.page - 1) * this.maxResultCount
				}).then(res => {
					this.usedList=res.obj.items
					this.usedList.forEach(item=>{
						item.tipsShow=false
					})
          this.total = res.obj.totalCount;
					console.log('usedList',this.usedList);
				}).catch(err => {
					
				})
			},
			// 已失效优惠券
			getExpireList() {
				this.ApiRequestPost('api/mall/ebactivity/customerreceiverecord/get-expire-list', {
					// "fCustomerID": 0,
					// "maxResultCount": 10,
					"filter": "",
					"sorting": "",
          maxResultCount: this.maxResultCount,
					skipCount: (this.page - 1) * this.maxResultCount
				}).then(res => {
					this.expireList=res.obj.items
					this.expireList.forEach(item=>{
						item.tipsShow=false
					})
					console.log('expireList',this.expireList);
          this.total = res.obj.totalCount;
				}).catch(err => {
					
				})
			},
			
			handleClick(val) {
        this.page = 1;
        this.maxResultCount = 9
        this.total = 0
				// console.log(val.name);
				if(val.name=='first'){
					this.getUnusedList()
				}
				if(val.name=='second'){
					this.getUsedList()
				}
				if(val.name=="third"){
					this.getExpireList()
				}
			},
			slideBtn(item) {

				// this.collectCouponsPopupData.forEach(function(val){
				//      val.tipsShow = false;
				//  })

				// item.tipsShow = !item.tipsShow;
				this.$forceUpdate()
				// console.log(item.tipsShow,1);
				// console.log(item)
				
			},
			// myCoupBtn(){
			//  this.$router.push({
			//  	path: '/about/Coupon',
			//  	query: {},
			//  });
			// },
			collectCouponsClick() {
			
				this.$router.push({
					path: "/CouponCollection",
					query: {}
				});
			}

		}
	};
</script>
<style lang="scss" scoped="scoped">
	.right-top-name {
		height: 18px;
		line-height: 18px;
	}
	::v-deep .el-tabs__item.is-active {
		@include themify($themes) {
			color: themed("themes_color") !important;
		}
	}
	::v-deep .el-tabs__item{
		width: 60px;
		padding: 0px;
		text-align: center;
	}

	::v-deep .el-tabs__active-bar {
		@include themify($themes) {
			background: themed("themes_color") !important;
		}
		height: 1px !important;
	}

	::v-deep .el-tabs__nav .el-tabs__item:hover {
		@include themify($themes) {
			color: themed("themes_color") !important;
		}
	}

	.tips-card {
		width: 702px;
		height: 674px;
		margin: -300px 26px 20px 28px;
		/* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); */
	}

	.dot-box {
		width: 12rpx;
		height: 12rpx;
	}

	#cuopon-btn {
		width: 110px;
		height: 30px;
		background-color: rgba(1, 115, 254, 1);
		box-sizing: border-box;
		box-shadow: none;
		font-size: 14px;
		color: #FFFFFF;
		line-height: 30px;
		text-align: center;
		position: absolute;
		right: 10px;
		top: 10px;
	}

	.coupon-Box {
		/* width: 917px; */
		height: 100%;
		/* background-color: #000; */
		/* 	margin-left: 450px; */
		position: relative;
	}

	.coupon-title {
		font-size: 18px;
		font-weight: bold;
		color: #000000;
	}

	.coupon-btn {
		position: absolute;
		top: 30px;
		right: 0px;
		left: 760px;
		width: 160px;
		height: 40px;
		background: #0173fe;
		border-radius: 2px;
		font-size: 14px;
		font-weight: 400;
		color: #ffffff;
	}

	::v-deep .el-tabs__nav-wrap::after {
		display: none;
	}

	::v-deep .el-tabs__header {
		margin: 0 0 0px;
	}

	.comBtn {
		width: 110px;
		height: 30px;
		line-height: 30px;
		cursor: pointer;
	}

	.couponCont {
		height: 100%;
		box-sizing: border-box;
		max-width: 1100px;
		background-color: #fff;
		padding: 10px 10px;
	}

	.coupList {
		width: calc(33.3%-12px);
		width: -webkit-calc(33.3% - 12px);
		margin-right: 18px;
		margin-bottom: 18px;
		min-height: 123px;
		float: left;
		word-break: break-all;
		z-index: 1;

	}

	.coupList:nth-child(3n) {
		margin-right: 0px;
	}

	.coupon-con {
		background: #ffffff;
		border-radius: 10px;
		box-shadow: 0px 0px 10px 0px rgba(13, 13, 13, 0.176470588235294);
		margin-left: 3px;
	}

	.collectCoupons-card1 {
		width: 100%;
		height: 94px;
		/* background-color: #FC503A; */
		color: #ffffff;
		display: flex;
		flex-direction: row;
		border-top-left-radius: 10px;
		background-image: linear-gradient(to bottom right, #FA8A77, #FC3720);
	}

	.collectCoupons-card1-none {
		width: 100%;
		height: 94px;
		/* background-color: #FC503A; */
		color: #ffffff;
		display: flex;
		flex-direction: row;
		border-top-left-radius: 10px;
		background-image: linear-gradient(to bottom right, #E0E0E1, #A7ACBB);
	}

	.collectCoupons-card2 {
		font-size: 20px;
		font-size: 20px;
		color: #ffffff;
	}

	.collectCoupons-card-left {
		width: 28%;
	}

	.collectCoupons-card3 {
		font-weight: 700;
		font-size: 24px;
		line-height: 40px;
		color: #ffffff;
	}

	.collectCoupons-card4 {
		width: 100%;
		height: 29px;
		border-bottom-left-radius: 10px;
		font-size: 12px;
		font-weight: 400;
		color: #cc3300;
		line-height: 29px;
		background-image: linear-gradient(to right, #FFCBB8, #FF9884);
		border-bottom-left-radius: 10px;

	}

	.collectCoupons-card4-none1 {
		width: 100%;
		height: 29px;
		border-bottom-left-radius: 10px;
		font-size: 12px;
		font-weight: 400;
		color: #999999;
		line-height: 29px;
		border-bottom-left-radius: 10px;
		background-color: #F2F2F2;
	}

	.collectCoupons-card6 {
		position: absolute;
		color: #000000;
		font-weight: 700;
		font-size: 14px;
		z-index: 1 !important;
	}

	.collectCoupons-card6-none2 {
		font-weight: 700;
		font-style: normal;
		color: #999999;
		font-size: 14px;
	}

	.collectCoupons-card7 {
		font-size: 12px;
		color: #999999;
		position: absolute;
		margin-top: 25px;
	}

	.collectCoupons-card8 {
		width: 63px;
		height: 18px;
		line-height: 18px;
		float: right;
		margin-top: 47px;
		cursor: pointer;
	}
	.collectCoupons-card29{
		float: left;
		width: 120px;
		height: 18px;
		margin-top: 47px;
		font-weight: 400;
	}

	.collectCoupons-card9 {
		display: flex;
		justify-content: space-between;
		border-top: 1px dashed #cccccc;
		padding-top: 5px;
	}

	.collectCoupons-card9 p {
		font-size: 12px;
		color: #999999;
		line-height: 18px;
	}

	.collectCoupons-card5 {
		height: 94px;
		padding-top: 13px;
		box-sizing: border-box;
	}

	.left-bottom {
		height: 58rpx;
		color: #000000;
		line-height: 58rpx;
		border-bottom-left-radius: 20rpx;
		background-color: #F2F2F2;
		color: #999999;
		;
	}

	.collectCoupons-card-right {
		width: 75%;
		padding: 0 10px;
		padding-left: 13px;
		position: relative;
		box-sizing: border-box;
	}

	.stateUse {
		position: absolute;
		top: 3px;
		right: 1px;
		font-size: 29px;
		z-index: 0;
	}

	body {
		position: relative;
	}
</style>
